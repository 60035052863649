.base-text-area {
    width: 100%;

    &.text-area-field {
        width: 100%;
        padding: 8px 13px;
        text-align: left;
        background: #FFFFFF;
        border: 1px solid $ls-color-gray--light;
        box-sizing: border-box;
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        &::placeholder {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $ls-color-gray;
        }

        &:focus {
            border: 1px solid #b9e18e;
        }

        &.invalid {
            border: 1px solid #FB7178 !important;
        }

        &.disabled {
            background: $ls-color-gray--light !important;
            color: $ls-color-gray--dark !important;
        }

        &-icon {
            padding-left: 35px;
        }

    }

}
