// Login - Style 3
// -------------------------
.login-page.login-3 {
    .site-wrapper {
        display: flex;
    }
    .login-box {
        color: #333;
        flex: 2;
        padding: 150px 60px 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        min-width: 450px;
        .box-wrapper {
            width: 100%;
        }
    }
    .content-box {
        background-image: url('/images/imagen.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        position: relative;
        flex: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
            color: #fff;
            font-size: 50px;
        }
        p {
            color: $ls-color-gray--very-light;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px
        }
    }
    .content-bottom {
        background-image: url('/images/login-vector3.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 100;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0;
        right: 32%;
    }
    .content-box::before {
        //background-image: url('/images/frame.svg');
        content: "";
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 300px;
        right: 0;
        position: absolute;
        top: 0;
        width: 420px;
        z-index: 1;
    }
    .content-box::after {
        background-image: url('/images/login-vector2.svg');
        content: "";
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        right: 7.5%;
        position: absolute;
    }
    .c-input {
        color: #555;
    }
    .login-box a {
        color: #b9e18e;
        &:hover {
            color: lighten(#333, 10%);
        }
    }
    .other-options h6 {
        color: #555;
    }
    .page-copyright {
        margin-top: 0;
        padding-top: 150px;
        color: $ls-color-gray--dark;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        text-align: left;
    }
    .page-copyright a {
        color: $ls-color-primary;
        &:hover {
            color: darken($ls-color-primary, 10%);
        }
    }
    .logo-main img {
        width: 200px;
    }
    @media (max-width: $large-breakpoint) {
        .content-box h1 {
            display: none;
        }
        .content-box p {
            display: none;
        }
        .content-box::before {
            height: 250px;
            width: 350px;
        }
        .content-box::after {
            right: 13%;
        }
    }
    @media (max-width: $medium-breakpoint) {
        .content-box h1 {
            font-size: 15px;
            margin-left: 50px;
        }
        .content-box p {
            display: none;
        }
        .content-bottom {
            width: 600px;
            height: 0px;
        }
        .content-box::before {
            height: 150px;
            width: 250px;
        }
    }
    @media(max-width: $small-breakpoint) {
        .page-copyright {
            padding-top: 110px
        }
        .login-box {
            width: 100%;
            max-width: none;
            min-width: 0;
            padding: 30px;
        }
        .content-box {
            display: none;
        }
    }
}