// Variables & Functions
//----------------------------------

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import 'variables';

// Plugins
//----------------------------------

@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/toastr/toastr";


// Skins
//----------------------------------

@import 'skins/skin-crater';


// Site Base
//----------------------------------

@import 'base';


// Layouts
//----------------------------------

@import 'layouts/layout';
@import 'layouts/crater-sidebar';


// Partials
//----------------------------------

@import 'partials/header';
@import 'partials/sidebar';


// Base Components
//----------------------------------

@import 'components/base/base-date-picker';
@import 'components/base/base-input';
@import 'components/base/base-button';
@import 'components/base/base-popup';
@import 'components/base/base-text-area';
@import "components/base/base-switch";
@import 'components/base/base-loader/index';
@import 'components/base/base-prefix-input';
@import 'components/v-tooltips.scss';


// Components
//----------------------------------

@import 'components/buttons';
@import 'components/forms';
@import 'components/cards';
@import 'components/tabs';
@import 'components/base-tabs';
@import 'components/tables';
@import 'components/hamburgers';
@import 'components/transitions';
@import 'components/charts';
@import "components/pace-loader";
@import "components/vue-tabs";
@import "components/vue-dropdown";
@import "components/vue-date-picker";
@import "components/vue-multi-select";
@import "components/customer-select";
@import "components/item-select";
@import "components/tax-select";
@import "components/avatar-cropper";
@import "components/payment-modes-modal";
@import "components/item-unit-modal.scss";
@import "components/mail-test-modal.scss";


// Modals
//----------------------------------

@import "components/base-modal";
@import "components/item-modal";
@import "components/template-modal";
@import "components/customer-modal";
@import "components/tax-type-modal";
@import "components/category-modal";


// Pages
//----------------------------------

@import 'pages/dashboard';
@import 'pages/login';
@import 'pages/login-3';
@import 'pages/404';
@import 'pages/customization';
@import 'pages/settings';
@import 'pages/invoices/create';
@import 'pages/invoices/view';
@import 'pages/invoices/index';
@import 'pages/estimates/create';
@import 'pages/estimates/index';
@import 'pages/estimates/view.scss';
@import 'pages/expenses';
@import 'pages/payments';
@import 'pages/settings.scss';
@import 'pages/wizard';
@import 'pages/reports.scss';
@import 'pages/customers';
@import 'pages/payments.scss';
@import 'pages/payment-view.scss';
@import 'pages/items.scss';
@import 'pages/statuses.scss';
@import 'pages/treasury_account.scss';

@import "../../../node_modules/handsontable/dist/handsontable.full.css";
