.treasuries-accounts {
  //
}

.relate-invoice-to-treasury-account {
  padding: 10px;
  
  .title {
    color: $ls-color-primary--light;
  }
}